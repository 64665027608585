import React, { useEffect } from "react";
import "./Header.css";
import { Link } from "react-router-dom";

const Header = () => {

  useEffect(() => {
      const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const scrollY = window.scrollY;
  
        const animatables = document.querySelectorAll('.animatable');
  
        animatables.forEach(animatable => {
          const elementTop = animatable.getBoundingClientRect().top + scrollY;
          const elementBottom = elementTop + animatable.clientHeight;
  
          // Check if element is in the viewport
          if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
            animatable.classList.add('animated');
            animatable.classList.remove('animatable');
          } else {
            // Reset the animation when the element is out of view
            animatable.classList.remove('animated');
            animatable.classList.add('animatable');
          }
        });
      };
  
      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Trigger animation on mount
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


  return (
    <section className="header--hp01-video-block" id="shadow" role="corporate-video">
      <div className="header--hp01-video-slider">
        <div>
          <div className="header--hp01-video-overlay">
            <video
              autoPlay
              loop
              muted
              preload="auto"
              playsInline
              className="header--hp01-video-desk" id="shadow"
              poster="./images/sh-img01.jpg"
            >
              <source
                type="video/mp4" id="shadow"
                src="./images/sh-vid01.mp4"
              />
            </video>
            <video
              autoPlay
              loop
              muted
              preload="auto"
              playsInline
              className="header--hp01-video-mob" id="shadow"
              poster="./images/sh-img02.jpg"
            >
              <source
                type="video/mp4" id="shadow" 
                src="./images/sh-vid02.mp4"
              />
            </video>
          </div>
          <div className="header--hp01-container">
            <div className="header--hp01-row">
              <div className="header--hp01-col-12">
                <div className="header--hp01-video-content">
                  {/* <div data-aos="fade-up" data-aos-delay="500">
                    <h1 className="block animatable bounceIn">Growth is Life</h1>

                    <ul className="block animatable bounceIn">
                      <Link to='/about'><li>
                        <a  >About Us</a>
                      </li></Link>
                      <Link to='/vision'><li>
                        <a  >Our Vision</a>
                      </li></Link>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;

import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import './Learnmore.css'

const LearnMoreSuprabhat = () => {
  return (
    <>
    
    <Navbar />

    <div className="LearnMorePage-container">
          <h1 className="LearnMorePage-title">Tech Generation Summer Camp 2025</h1>
          <p className="LearnMorePage-subtitle">
          Last year’s summer camp was a huge hit!
          </p>
    
          <div className="LearnMorePage-section">
            <h2 className="LearnMorePage-section-title">Camp Overview</h2>
            <p className="LearnMorePage-section-content">
            Over 336 students joined us and had an unforgettable experience.
            They explored exciting activities and launched their own websites and products using Artificial Intelligence.
            </p>
    
            <p className="LearnMorePage-section-content">
            Students even gave interviews during a grand press meet to showcase their creations.
            We ended the camp with a spectacular closing ceremony to celebrate their journey!
          </p>
    
          <p className="LearnMorePage-section-content">And now, we’re back—bigger and better!</p>
    
          <p className="LearnMorePage-section-content">
            This year, we are hosting the camp at:
          </p>
    
    
    
        <div className="flyers-container">
          <h1 id='font-color' className='LearnMorePage-title'>Suprabhat Model High School, Nacharam</h1>
          <div className="flyers-wrapper">
            <div className="flyer-card">
              <img
                src="./images/Suprabhatflyer01.jpg"
                alt="Flyer 1"
                className="flyer-image"
              />
            </div>
            <div className="flyer-card">
              <img
                src="./images/Suprabhatflyer02.jpg"
                alt="Flyer 2"
                className="flyer-image"
              />
            </div>
          </div>
        </div>
    
    
          </div>
    
          <div className="LearnMorePage-section">
            <h2 className="LearnMorePage-section-title">Key Activities</h2>
            <div className="LearnMorePage-activities-grid">
              <div className="LearnMorePage-activity-card">
                <h2>Give your child the chance to learn and explore:</h2>
                <ul>
                <li>Horse Riding</li>
            <li>Artificial Intelligence</li>
            <li>Photography</li>
            <li>Dance</li>
            <li>DJ Mixing</li>
            <li>Arts & Craft</li>
            <li>Swimming</li>
            <li>Archery</li>
            <li>Chess</li>
            <li>Coding and Machine Learning</li>
            <li>And so much more!</li>
                </ul>
              </div>
              {/* <div className="LearnMorePage-activity-card">
                <h2>High School (Ages 10 to 15)</h2>
                <ul>
                  <li>Horse Riding</li>
                  <li>Photography</li>
                  <li>Dance (Salsa, Ballet, Contemporary)</li>
                  <li>Coding (React JS Front-end Training)</li>
                  <li>Artificial Intelligence (Chatbot Integration & Generative AI)</li>
                  <li>Culinary Arts</li>
                  <li>Chess</li>
                  <li>DJing (Bollywood & Remixing)</li>
                  <li>Yoga and Calisthenics</li>
                </ul>
              </div> */}
            </div>
          </div>
    
          {/* <div className="LearnMorePage-section">
            <h2 className="LearnMorePage-section-title">Special Events</h2>
            <ul className="LearnMorePage-special-events">
              <li>
                <strong>Musical Night Camping (May 16th):</strong> Campfire, live music, and a special dinner under the stars.
              </li>
              <li>
                <strong>Exhibition (May 22nd):</strong> Showcase of creative and technological projects by students.
              </li>
              <li>
                <strong>Closing Ceremony (May 24th):</strong> Awards and performances to celebrate the camp's success.
              </li>
            </ul>
          </div> */}
    
          <div className="LearnMorePage-section">
            
    
            <p className="LearnMorePage-section-content">
            Don’t wait! Limited seats are available.
            Your child could be Hyderabad’s next big innovator or entrepreneur!
          </p>
          </div>
    
          <Link to="/enrollment" className="styled-link">
                <button className="button-2">Register Today and Let the Journey Begin!</button>
              </Link>
        </div>
    
    <Footer />
    
    </>
  )
}

export default LearnMoreSuprabhat
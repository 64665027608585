import React from "react";
import styles from "./HiringPartners.css";

const HiringPartners = () => {
  return (
    <div className="meet-our-partners-container">
      <div className="partners-heading-container">
        <p className="partners-heading">Meet our partners</p>
      </div>
      <div className="marquee-container">
        <div className="marquee">
          <div className="marquee-content">

            <div className="partner-logo-container">
              <img
                src="./images/MERIDIANLOGO.jpg"
                alt="MERIDIANLOGO"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/GDLOGO.PNG"
                alt="GDLOGO"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/soulsportza--logo.PNG"
                alt="soulsportza"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/nobel-logo.jpg"
                alt="nobel logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/alwick.jpg"
                alt="alwick"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/czar.jpg"
                alt="czar"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/stjosephs.jpeg"
                alt="stjosephs"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/vizag-sports-club-logo.jpeg"
                alt="vizag-sports-club-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/AekakshLogo.png"
                alt="Aekaksh Logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/shri-shiva-ganga-logo.png"
                alt="shri-shiva-ganga-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/NIHOC-LOGO.jpg"
                alt="NIHOC"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/Suprabhat-Model-High-School.png"
                alt="Suprabhat-Model-High-School"
                className="partner-logo"
              />
              
            </div>


            {/* ------------------------------- */}

            <div className="partner-logo-container">
              <img
                src="./images/MERIDIANLOGO.jpg"
                alt="MERIDIANLOGO"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/GDLOGO.PNG"
                alt="GDLOGO"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/soulsportza--logo.PNG"
                alt="soulsportza"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/nobel-logo.jpg"
                alt="nobel logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/alwick.jpg"
                alt="alwick"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/czar.jpg"
                alt="czar"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/stjosephs.jpeg"
                alt="stjosephs"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/vizag-sports-club-logo.jpeg"
                alt="vizag-sports-club-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/AekakshLogo.png"
                alt="Aekaksh Logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/shri-shiva-ganga-logo.png"
                alt="shri-shiva-ganga-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/NIHOC-LOGO.jpg"
                alt="NIHOC"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/Suprabhat-Model-High-School.png"
                alt="Suprabhat-Model-High-School"
                className="partner-logo"
              />
              
            </div>

            {/* ------------------------------------- */}


            {/* ------------------------------- */}

            <div className="partner-logo-container">
              <img
                src="./images/MERIDIANLOGO.jpg"
                alt="MERIDIANLOGO"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/GDLOGO.PNG"
                alt="GDLOGO"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/soulsportza--logo.PNG"
                alt="soulsportza"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/nobel-logo.jpg"
                alt="nobel logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/alwick.jpg"
                alt="alwick"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/czar.jpg"
                alt="czar"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/stjosephs.jpeg"
                alt="stjosephs"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/vizag-sports-club-logo.jpeg"
                alt="vizag-sports-club-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/AekakshLogo.png"
                alt="Aekaksh Logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/shri-shiva-ganga-logo.png"
                alt="shri-shiva-ganga-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/NIHOC-LOGO.jpg"
                alt="NIHOC"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/Suprabhat-Model-High-School.png"
                alt="Suprabhat-Model-High-School"
                className="partner-logo"
              />
              
            </div>

            {/* ------------------------------------- */}

            
            

            

            

          </div>
        </div>
      </div>
    </div>
  );
};

export default HiringPartners;

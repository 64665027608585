import React, { useEffect, useState } from "react";
import "./Registration.css";
import axios from "axios";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const Registration = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [school, setSchool] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loadingSendOtp, setLoadingSendOtp] = useState(false);
  const [loadingVerifyAndPay, setLoadingVerifyAndPay] = useState(false);
  const [countdown, setCountdown] = useState(120);

  const navigate = useNavigate();

  useEffect(() => {
    let intervalId;

    if (otpSent && countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [otpSent, countdown]);

  // Function to send OTP
  const sendOtp = async () => {
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }
  
    setLoadingSendOtp(true);
    try {
      const response = await axios.post(
        "https://learnoramabackend.learnorama.in/api/user/signup/send-otp",
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      alert(response.data.message || "OTP sent successfully.");
      setOtpSent(true);
    } catch (error) {
      if (error.response) {
        console.error("Error Response:", error.response.data);
  
        // Display a popup with the error details
        const errorMessage = error.response.data?.message?.email?.[0] || "An error occurred";
        alert(
          `Error: ${errorMessage}\n` 
        );
      } else if (error.request) {
        console.error("Error Request:", error.request);
        alert("No response received from the server.");
      } else {
        console.error("Error Message:", error.message);
        alert("An unexpected error occurred.");
      }
    } finally {
      setLoadingSendOtp(false);
    }
  };
  
  

  // Function to verify OTP and initiate payment
  const verifyAndPay = async () => {
    if (!otp || !name || !phone || !school) {
      alert("Please fill in all fields before proceeding.");
      return;
    }

    setLoadingVerifyAndPay(true);
    try {
      // Verify OTP
      const verifyResponse = await axios.post(
        "https://learnoramabackend.learnorama.in/api/user/verify-otp",
        {
          name,
          email,
          otp,
          phone,
          school,
        }
      );

      const token = verifyResponse.data.user.token;
      const encryptedToken = CryptoJS.AES.encrypt(token, "hello").toString();
      localStorage.setItem("authToken", encryptedToken);

      alert("Registration successful and OTP verified");

      // Initiate payment using the obtained token
      const paymentResponse = await axios.post(
        "https://learnoramabackend.learnorama.in/api/user/payment/init",
        {
          amount: 99,
          volume_id: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      window.location.href = paymentResponse.data.redirectUrl;
    } catch (error) {
      handleApiError(error, "Error verifying OTP or initiating payment");
    } finally {
      setLoadingVerifyAndPay(false);
    }
  };

  // Handle API errors
  const handleApiError = (error, defaultMessage) => {
    if (error.response) {
      alert(defaultMessage + ": " + (error.response.data.message || "Invalid request"));
    } else if (error.request) {
      alert(defaultMessage + ": No response received");
    } else {
      alert(defaultMessage + ": " + error.message);
    }
  };

  return (
    <>
      <Navbar />
      <h1 className="head-001">Future Proof AI Skills: Junior Prompt Engineering- Volume 1 PDF</h1>
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          background: "yellow",
          padding: "10px",
          width: "70%",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        Note: Every student who subscribes to the course will undergo an online
        exam. Qualified students will receive a Jr. Prompt Engineer certificate.
      </p>
      <div className="registration-form">
        <h2>Registration</h2>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={sendOtp} disabled={loadingSendOtp} className="button-2">
            {loadingSendOtp ? "Sending..." : "Send OTP"}
          </button>
          <p style={{ margin: "auto", textAlign: "center", paddingTop: "15px" }}>
            Already have an account?
            <Link
              style={{
                textDecoration: "none",
                color: "#fd7e14",
                margin: "auto",
                textAlign: "center",
              }}
              to="/login"
            >
              <span>Login Now</span>
            </Link>
          </p>
        </div>

        {otpSent && (
          <>
            <div>
              <label>Name:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label>OTP:</label>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            {countdown > 0 && (
              <p style={{ color: "red" }}>Time left for OTP verification: {countdown} seconds</p>
            )}
            <div>
              <label>Phone:</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div>
              <label>School:</label>
              <input
                type="text"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              />
            </div>
            <button
              onClick={verifyAndPay}
              disabled={loadingVerifyAndPay}
              className="button-2"
            >
              {loadingVerifyAndPay ? "Processing..." : "Verify OTP & Pay ₹99"}
            </button>
            <p>
              Already have an account?{" "}
              <Link
                style={{
                  textDecoration: "none",
                  color: "#fd7e14",
                  margin: "auto",
                  textAlign: "center",
                }}
                to="/login"
              >
                <span>Login Now</span>
              </Link>
            </p>
          </>
        )}
      </div>

      <Footer />
    </>
  );
};

export default Registration;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import necessary components
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Enrollment from './pages/Enrollment';
import Faqs from './pages/Faqs';
import Pages from './pages/Pages';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
// import Blogpg2 from './pages/BlogPages/Blogpg2';
// import Blogpg1 from './pages/BlogPages/Blogpg1';
// import Blogpg3 from './pages/BlogPages/Blogpg3';
// import Blogpg4 from './pages/BlogPages/Blogpg4';
// import Blogpg5 from './pages/BlogPages/Blogpg5';
import Gd from './pages/Schools/Gd';
import Meridian from './pages/Schools/Meridian';
import Rhythmic from './pages/Schools/Rhythmic';
import Peopleadv from './pages/Schools/Peopleadv';
import RefundPolicy from './pages/RefundPolicy';
import Login from './online-classes/authentication/Login';
import Registration from './online-classes/authentication/Registration';
import Welcome from './online-classes/Welcome';
import ChatGPT from './online-classes/Ai-learnorama/ChatGPT';
import OfflineCamps from './pages/OfflineCamps';
import WinterQuestCamp from './Events/WinterQuestCamp';
import Vol01Chapters from './online-classes/Volumes/Volume-01/Vol01Chapters';
import Volume01ChaptersLessons from './online-classes/Volumes/Volume-01/Volume01ChaptersLessions';
import LessonDetails from './online-classes/Volumes/Volume-01/LessonDetails';
import Chatbot from './components/floatingenrollButton/Chatbot';
import SpeedDialComponent from './components/floatingenrollButton/SpeedDialComponent';
import NutritionForKids from './pages/Nutrition For Kids/NutritionForKids';
import BelowSixYears from './pages/Nutrition For Kids/BelowSixYears';
import BelowTenYears from './pages/Nutrition For Kids/BelowTenYears';
import BelowFifteenYears from './pages/Nutrition For Kids/BelowFifteenYears.js';
import More from './pages/More.js';
import Animations from './components/Animations.js';
import Gallery from './pages/Gallery.js';
import ParentsTalk from './pages/ParentsTalk.js';
import Website from './pages/Website.js';
import AiMarketing from './pages/AiMarketing.js';
import LearnMorePage from './Events/LearnMorePage.js';
import ScrollToTop from './components/ScrollToTop.js';
import LearnMoreNihoc from './Events/summercampschools2025/LearnMoreNihoc.js';
import LearnMoreSuprabhat from './Events/summercampschools2025/LearnMoreSuprabhat.js';
import LearnMoreGd from './Events/summercampschools2025/LearnMoreGd.js';
// import EnrollDummy from './pages/EnrollDummy.js';
// import ImageGenerator from './online-classes/Ai-learnorama/ImageGenerator';




function App() {
  return (
    <React.Fragment>
      <ScrollToTop />
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/enrollment" element={<Enrollment />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/animations" element={<Animations />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/parentstalk" element={<ParentsTalk />} />
        <Route path="/website" element={<Website />} />
        <Route path="/aimarketing" element={<AiMarketing />} />
        {/* <Route path="/enrolldummy" element={<EnrollDummy />} /> */}
        {/* <Route path="/animations" element={<Animations />} /> */}
        {/* <Route path="/animations" element={<Animations />} /> */}
        
        <Route path="/chatbot" element={<Chatbot />} />
        
        <Route path="/pages" element={<Pages />} />
        <Route path="/more" element={<More />} />
        <Route path="/offlinecamps" element={<OfflineCamps />} />
        <Route path="/nutritionforkids" element={<NutritionForKids />} />
        <Route path="/belowsixyears" element={<BelowSixYears />} />
        <Route path="/belowtenyears" element={<BelowTenYears />} />
        <Route path="/belowfifteenyears" element={<BelowFifteenYears />} />

        
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsAndConditions" element={<TermsAndConditions />} />


        {/* <Route path="/blogpg1" element={<Blogpg1 />} />
        <Route path="/blogpg2" element={<Blogpg2 />} />
        <Route path="/blogpg3" element={<Blogpg3 />} />
        <Route path="/blogpg4" element={<Blogpg4 />} />
        <Route path="/blogpg5" element={<Blogpg5 />} /> */}

        <Route path="/gd" element={<Gd />} />
        <Route path="/meridian" element={<Meridian />} />
        <Route path="/peopleadv" element={<Peopleadv />} />
        <Route path="/rhythmic" element={<Rhythmic />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
        <Route path="/winterquestcamp" element={<WinterQuestCamp />} />
        

        

        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />

        <Route path="/welcome" element={<Welcome />} />
        <Route path="/chatGPT" element={<ChatGPT />} />
        {/* <Route path="/imageGenerator" element={<ImageGenerator />} /> */}

        
        <Route path="/vol01chapters" element={<Vol01Chapters />} />
        <Route path="/volume01chapterslessons" element={<Volume01ChaptersLessons />} />

        <Route path="/lesson/:chapterId/:lessonId" element={<LessonDetails />} />
        
        <Route path="/learnmorepage" element={<LearnMorePage />} />
        <Route path="/Learnmore-nihoc" element={<LearnMoreNihoc />} />
        <Route path="/LearnMore-suprabhat" element={<LearnMoreSuprabhat />} />
        <Route path="/LearnMore-gd" element={<LearnMoreGd />} />

        </Routes>
        <SpeedDialComponent />
    </React.Fragment>
  );
}

export default App;

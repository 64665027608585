import React, { useState, useEffect } from "react";
import "./Login.css";
import Navbar from "../../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import axios from "axios";
import CryptoJS from "crypto-js";

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [countdown, setCountdown] = useState(120);
  const [loading, setLoading] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    let intervalId;

    if (otpSent && countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [otpSent, countdown]);

  const handleGetOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const sendOtpResponse = await axios.post(
        "https://learnoramabackend.learnorama.in/api/user/signin/send-otp",
        {
          email: email,
        }
      );

      if (sendOtpResponse.status === 200) {
        setOtpSent(true);
        setStatusMessage("OTP sent successfully");
      } else {
        setStatusMessage("Failed to send OTP. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      setStatusMessage(
        "No account found with this email. Register to create an account."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const verifyOtpResponse = await axios.post(
        "https://learnoramabackend.learnorama.in/api/user/verify-otp",
        {
          email: email,
          otp: otp,
        }
      );

      if (verifyOtpResponse.status === 200) {
        setLoggedInUser(verifyOtpResponse.data.user);
        setStatusMessage("Login successful");

        // Encrypt and store token
        const token = verifyOtpResponse.data.user.token;
        console.log(token);
        const encryptedToken = CryptoJS.AES.encrypt(token, "hello").toString();

        localStorage.setItem("authToken", encryptedToken);

        navigate("/welcome"); // Redirect to /welcome using navigate
      } else {
        setStatusMessage("Login failed");
      }
    } catch (error) {
      console.error("Error occurred while verifying OTP:", error);
      setStatusMessage("Error occurred while verifying OTP");
    }
  };

  return (
    <>
      <Navbar />

      <h1 className="head-001">Future Proof AI Skills: Junior Prompt Engineering- Volume 1 PDF</h1>

      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          background: "yellow",
          padding: "10px",
          width: "70%",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "10px",
        }}>
        Note: Every student who subscribes to the course will undergo an online
        exam. Qualified students will receive a Jr. Prompt Engineer certificate.
      </p>

      <form className="login-form">
      <h2>LOGIN</h2>
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          style={{ marginBottom: "15px" }}
          onClick={handleGetOTP}
          disabled={loading}
          className="button-2">
          {loading ? "Loading..." : "Get OTP"}
        </button>
        {statusMessage && <p>{statusMessage}</p>}
        {otpSent && (
          <>
            <input
              type="text"
              placeholder="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <button type="button" onClick={handleLogin} className="button-2">
              Login
            </button>
            {countdown > 0 && (
              <p style={{ color: "red" }}>
                Time left for OTP verification: {countdown} seconds
              </p>
            )}
          </>
        )}
        <p>
          Don't have an account?{" "}
          <Link
            style={{
              textDecoration: "none",
              color: "#000",
              margin: "auto",
              textAlign: "center",
            }}
            to="/registration">
            <span style={{color: '#e71d36'}}>Register Now</span>
          </Link>
        </p>
      </form>

     

      <Footer />
    </>
  );
};

export default Login;
